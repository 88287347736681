.button,
.button__delete {
	border-radius: .5rem;
	font-family: inherit;
	font-size: 1.3rem;
	padding: 1rem 3rem;
	color: #e7e7ea;
	cursor: pointer;

	display: flex;
	align-items: center;
	gap: .5rem;
	transition: all .4s;
}
.button {
	background-color: #12151a;
	border: 1px solid #12151a;
}

.button .button__icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
	transition: all .4s;
}
.button:hover {
	background-color: transparent;
	border: 1px solid #12151a;
	color: #12151a;
}
.button:hover .button__icon {
	fill: #12151a;
}

.button__delete {
	background-color: #ff3366;
	border: 1px solid #ff3366;
}
.button__delete .button__icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
	transition: all .4s;
}
.button__delete:hover {
	background-color: transparent;
	border: 1px solid #ff3366;
	color: #ff3366;
}
.button__delete:hover .button__icon {
	fill: #ff3366;
}
