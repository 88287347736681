.login_container {
	grid-column: 1 / -1;
	grid-row: 1 / -1;
	background-color: #e7e7ea;
	background-repeat: no-repeat;
	background-size: cover;
	display: grid;
	gap: 3rem;
	grid-template-columns: 5rem repeat(6, 1fr) 5rem;
	grid-template-rows: 3rem repeat(4, 1fr) 3rem;
}
.login_image_div {
	grid-column: 4/-2;
	grid-row: 2/-2;
	align-self: center;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	border-radius: 2rem;
	height: 40vw;
	width: 100%;
	/* filter: blur(8px);
	-webkit-filter: blur(8px); */
	position: relative;
}
.login_image_div_content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 2rem;
	width: 50%;
	height: 80%;

	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	background-color: rgba(255, 255, 255, 0.5);

	display: flex;
	justify-content: center;
	align-items: center;
}
.login_image {
	height: 40vw;
}

.login_container__form {
	grid-column: 1/-1;
	grid-row: 1/-1;
	/* grid-column: 2/4;
	grid-row: 2/-2; */
	/* background-color: #ffffff; */
	/* height: 40vw; */
	align-self: center;
	justify-self: center;
	border-radius: 2rem;
	padding: 3rem 10rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	background-color: rgba(255, 255, 255, 0.7);
}

.login_container__logo {
	height: 5rem;
}

.login_container__heading {
	color: #12151a;
	font-weight: 200;
	font-size: 2rem;
	text-align: center;
}

.login_container__subheading {
	color: #8b8b8b;
	font-weight: 500;
	font-size: 1.3rem;
	text-align: center;
	margin-bottom: 1rem;
}

.login__copyright {
	margin-top: 2rem;
}

.login__copyright-text {
	text-align: center;
	font-size: 1.1rem;
	font-weight: 500;
	color: #8b8b8b;
}

.login__copyright-text a {
	text-decoration: none;
	color: #ff9000;
	font-weight: 600;
}

@media (max-width: 1024px) {
	.login_container__form {
		margin-top: 5rem;
		position: relative;
	}

	.login_image {
		width: 100%;
		height: auto;
		margin-top: 5rem;
		position: relative;
	}

	/* .login_container {
		background-color: #fff;
		display: block;
	} */
}
