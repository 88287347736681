.profileDetails {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
}

.profileCardStats {
	padding: 2rem;
	line-height: 4rem;
}

.profileContact {
	display: flex;
	gap: 2rem;
	font-size: 1.5rem;
}

.profileColumn {
	width: 20rem;
}

.profileContactHeading {
	display: flex;
	gap: 2rem;
	font-size: 1.6rem;
}

.profileLogo {
	height: 8rem;
	padding-left: 2rem;
}

.logindecs {
	font-size: 1.5rem;
}

.profileName {
	font-size: 2.5rem;
}

.trainerContainer {
	margin-top: 2rem;
	border-radius: 1rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	height: 72rem;
	width: 60%;
	background: #fff;
}

.modal__heading {
	border-bottom: 1px solid #ccc;
	padding: 0.5rem 2rem;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-weight: 600;
}
.modal__headingContainer {
	/* display: flex; */
	align-items: center;
	text-align: center;
	justify-content: space-between;
	padding: 0.5rem 2rem;
	margin-top: 2rem;
	margin-bottom: 1rem;
}
.modal__heading2 {
	font-weight: 600;
}

.signDataAddBtn {
	margin-top: .5rem;
	background-color: transparent;
	border: none;
	font-family: inherit;
	font-size: 1.1rem;
	color: #ff9000;
	font-weight: 500;
	background-color: transparent;
	border: 1px solid #ff9000;
	padding: .2rem 1rem;
	border-radius: 1rem;
	cursor: pointer;
	height: 3rem;
	display: flex;
	align-items: center;
	transition: all .4s;
}
.signDataAddBtnIcon {
	height: 1rem;
	width: 1rem;
	fill: #e7e7ea;
}
.signDataAddBtn:hover {
	background-color: #ff9000;
	border: 1px solid #ff9000;
	color: #000000;
}
