/* Report CSS */
.reportContent {
	display: flex;
	gap: 2rem;
	align-items: stretch;
}

.leftContainer {
	width: 75%;
}

.rightContainer {
	width: 25%;
	overflow: auto;
	border-radius: 2rem;
	gap: 2rem;
	flex-direction: column;
	display: flex;
}

.chartContent {
	background-color: #ffffff;
	border-radius: 2rem;
	margin: 2rem 0;
	padding: 2rem 3rem;
}

.chartContentHeader {
	display: flex;
	align-items: center;
	gap: 1rem;
	justify-content: space-between;
}

.chartContentHeading {
	font-size: 1.5rem;
	font-weight: 600;
}

.report_chart_container {
	max-height: 24rem;
}

.rightContent {
	background-color: #ffffff;
	padding: 2rem 3rem;
	width: 100%;
	max-height: 80rem;
	overflow: auto;
	border-radius: 2rem;
	margin-bottom: 2rem;
}

.rightContent2 {
	background-color: #ffffff;
	padding: 2rem 3rem;
	width: 100%;
	height: 45.5rem;
	overflow: auto;
	border-radius: 2rem;
}

.rightContent3 {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.rightContent4 {
	background-color: #ffffff;
	padding: 2rem 3rem;
	width: 100%;
	height: 30.5rem;
	overflow: auto;
	border-radius: 2rem;
}

.trainerList {
	text-decoration: none;
	color: currentColor;
	margin-top: 1rem;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
	background-color: #e7e7ea;
	padding: 1rem 2rem;
	border-radius: 2rem;
	transition: all 0.4s;
}

.trainerList_white {
	background-color: #f7f7f7;
	min-width: 35rem;
}

.trainerList:hover {
	transform: translateX(0.3rem);
	background-color: #ececec;
}

.trainerList_white:hover {
	transform: translateX(0.3rem);
	background-color: #ffffff;
}

.trainerListData {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.trainerListImage {
	width: 8rem;
	border-radius: 2rem;
}

.trainerListName {
	font-size: 1.3rem;
	font-weight: 600;
	color: #12151a;
	text-transform: capitalize;
}

.trainerListSessions {
	font-size: 1.2rem;
	font-weight: 600;
	color: #000000;
}

.trainerListSessions span {
	font-size: 2rem;
}

.trainerListEarning {
	font-size: 1.1rem;
}

.trainerListIcon {
	height: 2rem;
	width: 2rem;
	fill: #ff9000;
}

.button {
	text-decoration: none;
	background-color: transparent;
	border: none;
	font-family: inherit;
	font-size: 1.3rem;
	font-weight: 600;
	color: var(--primary-color);
}

@media (max-width: 1024px) {
	.leftContainer {
		width: 100%;
	}

	.rightContainer {
		width: 100%;
		overflow: auto;
		border-radius: 2rem;
		position: relative;
		gap: 2rem;
		flex-direction: column;
		display: flex;
	}

	.reportContent {
		width: 100%;
		height: auto;
		margin-top: 5rem;
		position: relative;
		display: block;
	}

	.reportContent2 {
		width: 100%;
		height: auto;
		margin-top: 5rem;
		position: relative;
	}

	.leftContent {
		width: 100%;
		height: auto;
		margin-top: 5rem;
		position: relative;
	}

	.rightContent {
		width: 100%;
		height: auto;
		position: relative;
	}

	.rightContent2 {
		width: 100%;
		height: auto;
		position: relative;
	}

	.rightContent3 {
		width: 100%;
		height: auto;
		margin-top: 5rem;
		position: relative;
	}

	.chartContent {
		width: 100%;
	}
}
