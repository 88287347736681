:root {
	--bg-primary: #15181f;
	--bg-secondary: #0c1014;
	--primary-color: #ff9000;
	--white: #e7e7ea;
	--pure-white: #ffffff;
	--lightest-white: #ececec;
	--black: #000000;
	--light-grey: #8b8b8b;
	--dark-grey: #12151a;
	--red: #ff3366;
	--yellow: #e1a82d;
	--blue: #09baa6;
	--green: #01a164;
	--dark-blue: #007c6e;
	--light-blue: #09baa567;
	--lightest-blue: #09baa54b;
	--box-shadow1: 0.5rem 0.5rem 2rem 2px #09baa51a;
	--box-shadow-input: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
}

* {
	margin: 0px;
	padding: 0px;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
}

body {
	box-sizing: border-box;
	background-color: #ffffff;
	color: #12151a;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	line-height: 1.7;
}

.Toastify {
	z-index: 99999999999;
}
.Toastify__toast--success {
	background-color: #09baa6 !important;
}
.Toastify__toast--error {
	background-color: #ff3366 !important;
}
.Toastify__toast--warning {
	background-color: #e1a82d !important;
}
.Toastify__toast-body {
	font-size: 1.4rem;
	font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
	width: 1rem;
	height: 1rem;
}
::-webkit-scrollbar-trackk {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-track-piece {
	background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
	background-color: #8b8b8b;
}
