.userDetails {
	margin-top: 1rem;
	display: flex;
	gap: 2rem;
	justify-content: space-around;
}

.user_detail_screen_main--content {
	display: flex;
}

.user_detail_screen_main-left {
	border-radius: 25px;
	transition: 0.3s;
	padding: 3rem 4rem;
	width: 40%;
}

.user_detail_screen_main_right {
	border-radius: 5rem;
	transition: 0.3s;
	display: flex;
}

.user_detail_screen_main_image {
	border-radius: 2rem;
	width: 2rem;
}

.userCard {
	text-decoration: none;
	color: currentColor;
	background-color: #ffffff;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	border-radius: 2rem;
	overflow: hidden;
	padding: 2rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
	width: 35rem;
	height: 25rem;
	margin: 1rem;
}

.userCard:hover {
	transform: scale(1.05);
}

.trainerTitle {
	text-decoration: none;
	color: #e7e7ea;
	font-size: 1.5rem;
	font-weight: 600;
	text-transform: capitalize;
}

.monthSessions {
	text-decoration: none;
	color: #e7e7ea;
	font-size: 1.8rem;
	font-weight: 600;
	text-transform: capitalize;
}

.monthCardIcon {
	width: 8rem;
	height: 8rem;
	fill: white;
}

.monthSessionTag {
	font-size: 5rem;
	width: 10rem;
	height: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.monthDate {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.headingCard {
	border-radius: 2rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	background-color: #ffffff;
	width: 25%;
}


.headingCardTrainer {
	border-radius: 2rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	background-color: var(--pure-white);
	width: 33.3%;
}


.headingCardTitle {
	color: #8b8b8b;
	font-weight: 600;
	font-size: 1.3rem;
}

.headingCardContent {
	display: flex;
	gap: 1rem;
	align-items: center;
	font-size: 3rem;
}

.headingCardContentGreen {
	color: #01a164;
}

.headingCardContentBlack {
	color: #000000;
}

.headingCardContentPrimary {
	color: #ff9000;
}

.headingCardContentRed {
	color: #ff3366;
}

.headingCardContentIcon {
	height: 4rem;
	width: 4rem;
}

.headingCardContentIconGreen {
	fill: #01a164;
}

.headingCardContentIconBlack {
	fill: #000000;
}

.headingCardContentIconPrimary {
	fill: #ff9000;
}

.headingCardContentIconRed {
	fill: #ff3366;
}

.headingCardDesc {
	font-size: 1rem;
	color: #8b8b8b;
	font-weight: 600;
}

.headingCircleOuter {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.headingCircleInner {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.monthCardCSS {
	display: flex;
	align-items: center;
	gap: 2rem;
	height: 15rem;
	width: 25rem;
	background-color: #12151a;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	border-radius: 2rem;
	overflow: hidden;
	padding: 2rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
}

.monthCardCSS:hover {
	transform: scale(1.05);
}

@media (max-width: 1024px) {
	.headingCard {
		height: auto;
		position: relative;
		width: 25% !important;
	}

	.headingCardTrainer {
		height: auto;
		position: relative;
		width: 33.3% !important;
	}

	.headingCardContent {
		font-size: 2rem;
	}

	.headingCardContentIcon {
		height: 2rem;
		width: 2rem;
	}
}
