.form_input {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: flex-start;
}
.form_input__label {
	color: #8b8b8b;
	font-size: 1.2rem;
}
.form_input__container {
	display: flex;
	align-items: center;
	gap: 1rem;
	background-color: #e7e7ea;
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}
.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: #12151a;
}
.form_input__box {
	font-family: inherit;
	background-color: transparent;
	color: #12151a;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}
.form_input__box::-webkit-input-placeholder {
	color: #8b8b8b;
}
.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
}
