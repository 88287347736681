.navigation {
	grid-column: 1 / 2;
	padding-top: 2rem;
	position: fixed;
	width: 22rem;
	height: 100vh;
	overflow: auto;
	z-index: 1000;
	transition: all 0.5s;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: all .4s;
}
/* .navigation:hover {
	overflow: auto;
} */

.navigation::-webkit-scrollbar {
	width: .5rem;
	height: .5rem;
}
.navigation::-webkit-scrollbar-track-piece {
	background-color: #0c1014;
}

.navigation::-webkit-scrollbar-thumb {
	background-color: #8b8b8b;
}

.navigation.active {
	width: 5.5rem;
	transition: all 0.5s ease;
}

.navigation__logo {
	height: 5rem;
	padding-left: 2rem;
}

.navigation__logo2 {
	margin-top: 1rem;
	height: 4.5rem;
	padding-left: 1rem;
	display: none;
}

.sidebarnav__button {
	/* display: none; */
	position: absolute;
	top: 50%;
	left: -.2rem;
	transform: translateY(-40%);
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

.sidebarnav__icon {
	height: 2rem;
	width: 2rem;
	fill: #12151a;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(10%, 150%);
	z-index: 1000;
	transition: all .4s;
}

.sidebarnav__icon--box {
	height: 8rem;
	width: 3.07rem;
	fill: #fff;
	position: absolute;
	top: 0;
	left: 0;
	transform: rotate(180deg);
}

.sidebarnav__button:hover .sidebarnav__icon {
	fill: #ff9000;
}

.navigation__list {
	margin-top: 3rem;
	width: 100%;
	padding-left: 0px;
	list-style: none;
}
.currentNav {
	/* display: flex;
	align-items: center; */
	position: relative;
}
.activeNav {
	position: absolute;
	top: 1rem;
	right: 0;
	height: 2rem;
	width: 0.5rem;
	background-color: #ff9000;
}
.currentNav .navigation__list--itemHover {
	background-color: #09baa54b;
}
.currentNav .navigation__list--itemHover .navigation__list--link {
	color: #000000;
	font-weight: 600;
}

.currentNav .navigation__list--itemHover .navigation__list--link .navigation__icon {
	fill: #12151a;
}

.navigation__list--item {
	border-radius: 1rem;
	display: none;
	transition: all 0.4s;
}

.navigation__list--copyright {
	padding: 1rem 0;
	padding-left: 2rem;
	font-size: 1.2rem;
	font-weight: 500;
	width: 22rem;
}

.navigation__list--copyright span {
	font-size: 1rem;
	font-weight: 600;
	color: #12151a;
}

.navigation__list--copyright span a {
	font-size: inherit;
	font-weight: inherit;
	color: currentColor;
	text-decoration: none;
	transition: all .4s;
}

.navigation__list--copyright span a:hover {
	color: #ff9000;
}

.navigation__list--copyrightHidden {
	padding: 1rem 0;
	padding-left: 2rem;
	font-size: 1.2rem;
	font-weight: 500;
	display: none;
}

.navigation__list--itemHover {
	border-radius: 1rem;
	width: 20rem;

	transition: all 0.4s;
	/* transform: translateX(-20rem); */
	display: block;
	cursor: pointer;
}

.navigation__list--itemHover:hover,
.navigation__list--item:hover {
	background: #ff9000;
	transform: translateX(0.2rem);
}

.navigation.active .navigation__list--itemHover {
	display: none;
}

.navigation.active .navigation__list--item {
	display: block;
}

.navigation.active .navigation__list--copyright {
	display: none;
}

.navigation.active .navigation__list--copyrightHidden {
	display: block;
}

.navigation.active .navigation__logo {
	display: none;
}

.navigation.active .navigation__logo2 {
	display: block;
}

.navigation__list--link {
	text-decoration: none;
	color: #12151a;
	font-size: 1.2rem;
	font-weight: 500;
	padding: 1rem 0;
	padding-left: 2rem;
	display: flex;
	gap: 1rem;
	align-items: center;
}

.hasChildren .navigation__list--link {
	padding: 0;
}

.navigation__list--link--span {
	display: flex;
	align-items: center;
	gap: 1.2rem;
}

.navigation__list--link2 {
	text-decoration: none;
	color: #12151a;
	font-size: 1.2rem;
	font-weight: 500;
	padding: 1rem 0;
	padding-left: 2rem;
	display: flex;
	gap: 1rem;
	align-items: center;
}

.navigation__icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: #12151a;
}

.navigation__icon2 {
	height: 1.5rem;
	width: 1.5rem;
	fill: #12151a;
}

.hasChildren {
	position: relative;
	padding: 1rem 0;
	padding-left: 2rem;
}
.hasChildren::after {
	cursor: pointer;
	padding-right: 1rem;
	content: ' \002B';
	color: #12151a;
	font-size: 1.7rem;
	position: absolute;
	right: 0rem;
	top: .7rem;
}
/* .subMenuActive {
	background-color: #ff9000;
} */
.subMenuActive.hasChildren::after {
	content: ' \002D';
}
.subMenuActive.hasChildren ul {
	display: block;
}
.navigation__sub__active {
	color: #000000 !important;
	font-weight: 600 !important;
}
.navigation__sub__active svg {
	fill: #000000 !important;
}
.hasChildren ul {
	margin-top: 1rem;
	display: none;
	list-style: none;
}
.hasChildren ul li {
	padding: .5rem 1rem;
	transition: all .4s;
}
.hasChildren ul li:hover {
	background-color: #e1a82d;
}
.hasChildren ul li a {
	text-decoration: none;
	color: #12151a;
	font-size: 1.1rem;
	font-weight: 500;
	display: flex;
	gap: .5rem;
	align-items: center;
}
.hasChildren ul li a svg {
	height: 1.2rem;
	width: 1.2rem;
	fill: #12151a;
}
.navigation__list--link2--logout {
	background: transparent;
	box-shadow: none;
	border: 0;
	font-family: 'Poppins', sans-serif;
	cursor: pointer;
}
