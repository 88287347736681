.insuarance_img{
    max-height: 50vh !important;
    max-width: 60vh !important;
    overflow: scroll;
}

.insuarance_img img{
    max-height: 50vh !important;
    max-width: 60vh !important;
    overflow: scroll;
}

.sort__icon__button{
	margin: 0 .5rem;
    background-color: rgb(212, 235, 242);
	border-radius: 50%;
	display: inline-block;
	align-items: center;
	transition: all .4s;
    border: 0px;
    padding: 0.5rem 0.6rem;
}

.sort__icon__button:hover {
    background-color: rgb(212, 235, 242);
}

.sort__icon {
	height: 1.2rem;
	width: 1.2rem;
	fill: #12151a;
	z-index: 1000;
	transition: all .4s;
}

.sort__icon__hover{
	height: 1.2rem;
	width: 1.2rem;
	fill: #12151a;
	z-index: 1000;
}

.sort__icon__button__hover{
	margin: 0 .5rem;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	transition: all .4s;
    border: 0px;
	padding: 0.5rem;
    background-color: transparent;
}

.sort__icon__button__hover:hover{
	margin: 0 .5rem;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	transition: all .4s;
    border: 0px;
    background-color: rgb(212, 235, 242);
}

.sort__icon__button__hover:hover .sort__icon__hover{
	fill: #12151a;
}