.muscleType {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.muscleTypeSelectBox {
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-size: 1.5rem;
}

.templateCardAction {
  background-color: transparent;
  padding: 0.6rem;
  display: flex;
  border: 1px solid #12151a;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.4s;
  margin-top: 0.6rem;
  cursor: pointer;
  width: 2.8rem;
  height: 2.8rem;
}

.templateCardAction:hover {
	background-color: #12151a;
	border: 1px solid #12151a;
}
.templateCardAction:hover .templateCardActionIcon {
	fill: #ffffff;
}


.roundInfoContainer {
  display: flex;
  margin: 0.4rem 1rem 1rem;
  align-items: center;
  justify-content: space-between;
}

.roundInfoContainer span {
  font-size: 1.4rem;
  padding-top: 2rem;
}

.muscleTypeGoBtn {
  background-color: #ff9000;
  padding: 0.5rem;
}

.muscleTypeGoBtnIcon {
  height: 2rem;
  width: 2rem;
  fill: #e7e7ea;
}

.muscleTypeGoBtn,
.muscleTypeEditBtn {
  font-family: inherit;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #e7e7ea;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.muscleTypeEditBtn {
  background-color: #09baa6;
  padding: 0.5rem 1.5rem;
}
.muscleTypeEditBtnIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: #e7e7ea;
}

/* Round Card */
.roundCards {
  display: flex;
  gap: 3.5rem;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 3rem;
}
.roundCard {
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 2rem;
  width: 38rem;
  height: fit-content;
}
.roundCardHeading {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--bg-primary);
}
.roundCardExercise {
  display: flex;
  gap: 3rem;
  font-size: 1.2rem;
  font-weight: 600;
  align-items: top;
}
.roundCardExerciseReps {
  color: #ff9000;
  font-size: 5rem;
}
.roundCardDetail1 {
  width: 70%;
}
.roundCardText {
  font-size: 1.5rem;
  font-weight: 400;
  margin-right: 10px;
  min-width: 120px;
}
.roundCardText1 {
  font-size: 1.5rem;
  font-weight: 400;
}
.roundCardInfo {
  font-size: 1.5rem;
  font-weight: 500;
  /* width: 111px; */
  height: auto;
}
.roundCardDetail2 {
  text-align: center;
}
.roundCardExerciseName {
  font-size: 2rem;
  color: var(--bg-primary);
}
.roundCardRepType {
  font-size: 1.3rem;
  font-weight: 600;
}
.roundCardRepType2 {
  font-size: 1.1rem;
  font-weight: 600;
}
.roundCardNote {
  font-size: 1.1rem;
  font-weight: 600;
}
.roundCardNextRound {
  color: #09baa6;
  font-size: 1.2rem;
  font-weight: 600;
}

/* Add Muscle Type */
.inputBoxContainers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  height: 26rem;
  margin-bottom: 2rem;
  overflow: auto;
}
.inputBoxContainer {
  background-color: #ffffff;
  border-radius: 2rem;

  padding: 2rem;
}

.inputBoxTopContainer {
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 1.4rem;
  display: inline-block;
}
.inputBoxes {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.info_container {
  display: flex;
  margin-top: 1.2rem;
  justify-content: space-between;
}

.excersie_name {
  color: #8b8b8b;
  font-size: 1.2rem;
}

.close:hover {
  color: red;
}

/* input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
} */

.roundCardTextTitle {
  display: flex;
}

.custom_button {
  border: none;
  color: black;
  font-family: "Poppins", sans-serif;
  padding: 10px 20px 10px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.3s, color 0.3s;
}

.custom_button1 {
  border: none;
  color: black;
  background: none;
  font-family: "Poppins", sans-serif;
  padding: 10px 20px 10px;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.3s, color 0.3s;
}

.custom_button1:hover {
  font-family: "Poppins", sans-serif;
  background: #ff9100;
  color: white;
}

.custom_button:hover {
  font-family: "Poppins", sans-serif;
  background: #ff9100;
  color: white;
}

.saveButton {
  display: flex;
}
