.month {
	width: 100%;
	background: #ffffff;
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	overflow-y: auto;
	overflow-x: auto;
	background-color: #ffffff;
	padding: 4rem;
}

.month ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.headers {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
	margin-bottom: 2rem;
}
.dayMonth {
	text-align: center;
	font-size: 1.3rem;
	line-height: 1.3;
}
.dayMonth span {
	font-size: 1.5rem;
	font-weight: 600;
	display: block;
}
.prevNextBtn {
	background-color: transparent;
	border: none;
	outline: none;
	color: #ff9000;
	font-family: inherit;
	font-size: 2rem;
	cursor: pointer;
}
.weekdays {
	margin: 0;
	background-color: #8b8b8b;
	display: flex;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
}
.weekdays li {
	width: 13.6%;
	padding: 1.2rem 0;
	font-size: 1.5rem;
	color: #12151a;
	text-align: center;
}
.days {
	padding: 10px 0;
	background: #e7e7ea;
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
}
.days li {
	min-width: 13.6%;
	height: 10rem;
	border: 1px solid #ffffff;
	text-align: center;
	font-size: 2rem;
	color: #12151a;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	line-height: 1;
	position: relative;
}
.lockIcon {
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
	fill: #ff3366;
}
.sessionData {
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	gap: .5rem;
}
.sessionData span {
	font-size: 3rem;
	color: #09baa6;
}
.days li .active {
	/* padding: .8rem;
	line-height: 1;
	background: #ff9000;
	border-radius: 50%; */
	color: #ff9000;
	/* border-bottom: 1px solid #ff9000; */
}
.totalSection {
	margin: 2rem 0;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: flex-end;
}
.totalSectionData {
	font-size: 1.3rem;
	color: #09baa6;
	display: flex;
	gap: .5rem;
	align-items: center;
}
.totalSectionData span {
	font-size: 1.5rem;
	font-weight: 600;
}
.sessionDataAddBtn {
	margin-top: .5rem;
	background-color: transparent;
	border: none;
	font-family: inherit;
	font-size: 1.1rem;
	color: #12151a;
	font-weight: 500;
	background-color: transparent;
	border-bottom: 1px solid #09baa6;
	padding: .2rem 1rem;
	border-radius: 1rem;
	cursor: pointer;

	display: flex;
	align-items: center;
	transition: all .4s;
}
.sessionDataAddBtnIcon {
	height: 1rem;
	width: 1rem;
	fill: #12151a;
}
.sessionDataAddBtn:hover {
	border-bottom: 1px solid #12151a;
	color: #09baa6;
}
.sessionDataBtn {
	background-color: transparent;
	border: none;
	cursor: pointer;
}
.sessionDataIcon {
	height: 1rem;
	width: 1rem;
	fill: #ff9000;
}
.sessionInputs {
	margin-top: .5rem;
	background-color: #000000;
	border-radius: 1rem;
	padding: .2rem 1rem;
	display: flex;
	gap: 1rem;
	align-items: center;
}
.sessionInput {
	color: #e7e7ea;
	border: none;
	background-color: transparent;
	width: 5rem;
	font-family: inherit;
	outline: none;
}
.sessionInputBtn {
	background-color: transparent;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.sessionInputIcon_check {
	height: 1.3rem;
	width: 1.3rem;
	fill: #ff9000;
}
.sessionInputIcon_close {
	height: 1rem;
	width: 1rem;
	fill: #ff3366;
}
.errorText {
	margin-top: .5rem;
	color: #ff3366;
	font-size: .8rem;
}

/* Add media queries for smaller screens */
@media screen and (max-width: 720px) {
	.weekdays li,
	.days li {
		width: 13.1%;
	}
}

@media screen and (max-width: 420px) {
	.weekdays li,
	.days li {
		width: 12.5%;
	}
	.days li .active {
		padding: 2px;
	}
}

@media screen and (max-width: 290px) {
	.weekdays li,
	.days li {
		width: 12.2%;
	}
}
