.container {
	display: grid;
	grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
	height: 100vh;
	width: 100%;
	transition: all 0.5s ease;
}

.mainContent {
	grid-column: 2 / -1;
	overflow: hidden;
	transition: all 0.5s ease;
	margin: 1.5rem 0;
	margin-right: 1.5rem;
	background-color: #e7e7ea;
	/* box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a; */
	border-radius: 2rem;
	position: absolute;
	left: 22rem;
	top: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 50%;
}

.mainContent_for_adsscreen {
	grid-column: 2 / -1;
	overflow: hidden;
	transition: all 0.5s ease;
	margin: 0.5rem;
	margin-right: 1.5rem;
	background-color: #e7e7ea;
	border-radius: 2rem;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 50%;
}

.mainContent__active {
	transition: all 0.5s ease;
	left: 6rem;
}

.content {
	margin-top: 3.5rem;
	height: 85vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding-left: 4rem;
	padding-right: 4rem;
	padding-bottom: 3rem;
	padding-top: 1rem;
}

.not_data_found {
	width: 100%;
	text-align: center;
	/* text-transform: capitalize; */
}

.flex {
	display: flex;
	gap: .5rem;
	align-items: center;
}

.flex {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.gap-1 {
	gap: 1rem;
}

.gap-2 {
	gap: 2rem;
}

.gap-3 {
	gap: 3rem;
}

.flexWrap {
	flex-wrap: wrap;
}

.justifySpaceBetween {
	justify-content: space-between;
}

.justifyCenter {
	justify-content: center;
}

.tableContainer--paginater {
	padding-bottom: 3rem;
	display: flex;
	justify-content: flex-end;
}

.tableContainer {
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	background-color: #ffffff;
	border-radius: 1rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	height: 35vh;
	/* padding-right: 1rem; */
	overflow-y: auto;
	overflow-x: auto;
	/* transition: all .4s; */
}

.table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	background-color: #ffffff;
	overflow: hidden;
	/* position: relative; */
}

th {
	position: sticky;
	top: 0;
	z-index: 100;
}

tr:nth-child(even) {
	background-color: #ececec;
}

.table tr th {
	white-space: nowrap;
	background-color: #09baa6;
	color: var(--dark-color);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
	position: sticky;
	top: 0;
	z-index: 100;
}

.table tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #12151a;
	text-align: left;
}

.table tr {
	transition: all 0.8s;
	font-size: 1.2rem;
}

.table__icon {
	height: 1.5rem;
	width: 1.5rem;
}

.table__iconBox {
	display: flex;
	align-items: center;
	/*change this to center*/
	justify-content: left;
	/*change this to center*/
	gap: 0.5rem;
}

.table__button {
	border: none;
	background-color: transparent;
	border: 1px solid #ff9000;
	outline: none;
	padding: .7rem;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.4s;

	display: flex;
	align-items: center;
	position: relative;
}

.table__button span {
	display: none;
	font-size: 1rem;
	color: #e7e7ea;
	background-color: #000000;
	padding: 0.5rem 1rem;
	border-radius: 10rem;
	position: absolute;
	top: -2rem;
	/* left: 0; */
	/* transform: translate(-50%, -50%); */
	transition: all 1s;
	right: 1rem;
	z-index: 1000;
}

.table__button:hover span {
	display: block;
}

.table__button:hover {
	background-color: #ff9000;
	border: 1px solid #ff9000;
}

.table__button:hover .table__button--icon {
	fill: #e7e7ea;
}

.table__button--icon {
	height: 1.2rem;
	width: 1.2rem;
	fill: #ff9000;
}

.table__button--delete {
	border: 1px solid #ff3366;
}

.table__button--delete:hover {
	background-color: #ff3366;
	border: 1px solid #ff3366;
}

.table__button--delete:hover .table__button--icon-red {
	fill: #e7e7ea;
}

.table__button--icon-red {
	height: 1.2rem;
	width: 1.2rem;
	fill: #ff3366;
}

.table__button--second {
	margin-right: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	text-decoration: none;
	color: #12151a;
	border: 1px solid #12151a;
	border-radius: 5rem;
	font-size: 1.1rem;
	cursor: pointer;
	transition: all .4s;
}

.table__button--second span {
	margin-left: 1rem;
}

.table__button--secondIconContainer {
	margin: .5rem;
	padding: .5rem;
	background-color: #12151a;
	border-radius: 50%;
	display: flex;
	align-items: center;
	transition: all .4s;
}

.table__button--secondIcon {
	height: 1rem;
	width: 1rem;
	fill: #e7e7ea;
	transition: all .4s;
}

.table__button--second:hover {
	background-color: #12151a;
	border: 1px solid #12151a;
	color: #e7e7ea;
}

.table__button--second:hover .table__button--secondIconContainer {
	background-color: #e7e7ea;
}

.table__button--second:hover .table__button--secondIcon {
	fill: #12151a;
}

.table__button--icon-green {
	height: 1.2rem;
	width: 1.2rem;
	fill: #09baa6;
}

.table__button--icon--disabled {
	fill: #c6d1d0;
}

.table__button--disabled {
	border-color: #c6d1d0;
}

.table__button--icon--disabled:hover {
	background-color: #c6d1d0;
	border-color: #c6d1d0;
}

.table__button--disabled:hover {
	background-color: #c6d1d0;
	border-color: #c6d1d0;
}

.table__button--changepwd {
	border: 1px solid #09baa6;
}

.table__button--changepwd:hover {
	background-color: #09baa6;
	border: 1px solid #09baa6;
}

.table__button--changepwd:hover .table__button--icon-green {
	fill: #e7e7ea;
}

.table__button--icon-doc {
	height: 1.2rem;
	width: 1.2rem;
	fill: #007c6e;
}

.table__button--doc {
	border: 1px solid #007c6e;
}

.table__button--doc:hover {
	background-color: #007c6e;
	border: 1px solid #007c6e;
}

.table__button--doc:hover .table__button--icon-doc {
	fill: #e7e7ea;
}

.table__button--icon-setting {
	height: 1.2rem;
	width: 1.2rem;
	fill: #ff3366;
}

.table__button--setting {
	border: 1px solid #ff3366;
}

.table__button--setting:hover {
	background-color: #ff3366;
	border: 1px solid #ff3366;
}

.table__button--setting:hover .table__button--icon-setting {
	fill: #e7e7ea;
}

/* Toggle Button Start */
.switch {
	position: relative;
	display: inline-block;
	min-width: 4rem;
	height: 2.5rem;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #8b8b8b;
	box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 1.7rem;
	width: 1.7rem;
	left: 4px;
	bottom: 4.5px;
	background-color: #12151a;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.checkbox {
	opacity: 0;
}

.checkbox:checked+.slider {
	background-color: #ff9000;
}

.checkbox:focus+.slider {
	box-shadow: 0 0 1px #ff9000;
}

.checkbox:checked+.slider:before {
	background-color: #e7e7ea;
	-webkit-transform: translateX(1.3rem);
	-ms-transform: translateX(1.3rem);
	transform: translateX(1.3rem);
}

.slider.round {
	border-radius: 3.4rem;
}

.slider.round:before {
	border-radius: 50%;
}

.tableContainer--paginater {
	padding-bottom: 3rem;
	display: flex;
	justify-content: flex-end;
}

.paginationTop {
	margin-bottom: "-4rem";
}

.paginationActive {
	background-color: #12151a;
}

.paginationActive a {
	color: #e7e7ea !important;
}

.paginationBttns {
	list-style: none;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.paginationBttns li {
	height: 3rem;
	width: 3rem;
	border: 1px solid #12151a;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all 0.4s;
	overflow: hidden;
}

.paginationBttns a {
	padding: 2rem;
	/* margin: .8rem; */
	/* border: 1px solid #ff9000; */
	color: #12151a;
	cursor: pointer;
	transition: all 0.4s;
}

.paginationBttns li:hover {
	color: #e7e7ea;
	border: 1px solid #12151a;
	background-color: #12151a;
}

.paginationBttns a:hover {
	color: #e7e7ea;
}

.break {
	border: none !important;
	background-color: transparent !important;
	box-shadow: none !important;
	font-size: 2rem !important;
}

.break a:hover {
	color: #ff9000 !important;
}

.paginationDisabled {
	background-color: transparent;
	border: 1px solid #8b8b8b !important;
}

.paginationDisabled a {
	color: #8b8b8b;
	background-color: var(--light-dark);
	border: 1px solid var(--light-dark);
}

.paginationDisabled a:hover {
	background-color: #8b8b8b;
	border: 1px solid var(--light-dark) !important;
	cursor: unset;
}

.check-in-btn {
	border: 1px solid #ff9000;
	background-color: #ff9000;
	outline: none;
	font-family: inherit;
	cursor: pointer;
	border-radius: 0.5rem;
	position: relative;
	transition: all 1s ease;
	color: white;
	padding: 0.8rem;
}

.check-in-btn:hover {
	border: 1px solid #ff9000;
	background-color: transparent;
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 0.5rem;
	position: relative;
	transition: all 1s ease;
	color: white;
}

.check-out-btn {
	border: 1px solid #ff9000;
	background-color: #ff9000;
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 1rem;
	position: relative;
	transition: all 1s ease;
	color: white;
}

.check-out-btn:hover {
	border: 1px solid #ff9000;
	background-color: transparent;
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 1rem;
	position: relative;
	transition: all 1s ease;
	color: white;
}

#react-select-2-listbox {
	z-index: 999 !important;
}

.css-b62m3t-container {
	position: unset !important;
	margin-left: auto !important;
}


.react-select {
	width: 100%;
	max-width: 200px;
}


.css-1s2u09g-control {
	background-color: rgb(231 231 234) !important;
	border-color: hsl(240deg 7% 91%) !important;
}

.css-1pndypt-Input {
	color: rgb(0, 0, 0) !important;
}

.css-tlfecz-indicatorContainer {
	color: hsl(0deg 0% 0%) !important;
}

#react-select-2-listbox {
	z-index: 10 !important;
}

#react-select-2-input {
	opacity: 1 !important;
}

#react-select-3-input {
	opacity: 1 !important;
}

.check-in-header {
	display: flex;
	gap: 2rem;
	align-items: center;
	width: max-content;

	background-color: #0c1014;
	border-radius: 1rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	margin: 2rem 0;
	padding: 3rem 3rem;
}

.css-1pahdxg-control:hover {
	border-color: #646464 !important;
	box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
}

.css-qc6sy-singleValue {
	opacity: 0;
}

/* 
.css-1s2u09g-control:hover {
	background-color: hsl(210deg 25% 6%) !important;
	box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
	border-color: #ff9000 !important;
}


.css-6j8wv5-Input {
	color: #fff !important;
	text-shadow: 0px 0px 0px #000;
	-webkit-text-fill-color: transparent;
	background-color: transparent;
	border: none;
	outline: none;
}

.css-6j8wv5-Input::-webkit-input-placeholder {
	color: rgb(197, 197, 197) !important;
}

.css-1pndypt-Input {
	color: #fff !important;
}

.css-1pahdxg-control {
	border-color: #ff9000 !important;
	background-color: hsl(210deg 25% 6%) !important;
}

.css-1s2u09g-control {
	background-color: hsl(210deg 25% 6%) !important;
}

.css-qc6sy-singleValue {
	color: #fff !important;
	font-size: 1.2rem !important;
} */

option:focus-visible,
option:focus-within,
option:target,
option:hover,
option:focus,
option:active,
option:checked {
	background: #ff9000;
	background-color: #ff9000;
}

.optionBox:active {
	background: #ff9000;
	background-color: #ff9000;
	border-radius: 0rem;
}

/* 
.css-ackcql {
	color: #fff !important;
}

.css-14el2xx-placeholder {
	color: #fff !important;
}

.css-vwja0k {
	color: #fff !important;
} */

.Toastify__toast-container--bottom-right {
	z-index: 999999 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
	cursor: pointer;
	border-radius: 4px;
	margin-right: 2px;
	opacity: 0.6;
	filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
	opacity: 1;
}

.btn-file {
	margin-top: 1rem;
	padding: 2rem 3rem;
	border-radius: 2rem;
	height: 16rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #e7e7ea;
	border-radius: 1rem;
	cursor: pointer;
	padding: 0rem 2rem;
	min-height: 9rem;
	min-width: 16rem;
}

.btn-file input[type=file] {
	opacity: 0;
	outline: none;
	background: white;
	cursor: inherit;
	display: block;
	border-radius: 45rem;
	height: 5rem;
	margin-left: -25rem;
}

.file_upload-image {
	margin-left: -8rem;
}

.file_upload--icon {
	fill: #12151a;
	height: 3rem;
	width: 3rem;
}

.textCapitalize {
	text-transform: capitalize;
}

.inputBox__toggle {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 2rem;
	font-size: 1.3rem;
	color: #ff9000;
}

.inputBox__toggle--label {
	font-size: 1.5rem;
}

.sign_hide {
	display: none;
}

.sign_show {
	display: block;
}

.tableContainer_order .textBox__input--box {
	margin-left: 0rem;
}

.text_wrap_desc {
	white-space: pre-wrap !important;
	max-width: 25rem;
}

#progress-spinner {
	border-radius: 50%;
	height: 100px;
	width: 100px;
}

#middle-circle {
	position: absolute;
	border-radius: 50%;
	height: 80px;
	width: 80px;
	background-color: rgb(248, 248, 248);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: large;
	font-weight: bold;
}

.month_filter {
	display: flex;
	gap: 2rem;
	align-items: center;
	width: max-content;
	background-color: #0c1014;
	border-radius: 1rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	margin: 1rem 0;
}

.month_filter .textBox__input--box {
	margin-left: 0rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.totalCountData {
	font-size: 1.3rem;
	color: #09baa6;
	display: flex;
	gap: .5rem;
	align-items: center;
	margin-right: 1rem;
}

.totalCountData span {
	font-size: 1.5rem;
	font-weight: 600;
}

.react-google-flight-datepicker .dialog-header {
	display: none;
}

.react-google-flight-datepicker .date-picker-input {
	height: 40px;
	width: 35rem;
}

.react-google-flight-datepicker .dialog-date-picker {
	z-index: 999 !important;
	width: 290px !important;
	top: 75px;
}


.react-google-flight-datepicker .day {
	width: 30px;
	height: 30px;
}

.react-google-flight-datepicker .date {
	min-width: 140px;
}

.dialog-header .date-picker-input {
	min-width: 350px !important;
	width: 100% !important;
}

.react-google-flight-datepicker .reset-button {
	display: none;
}

.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
	border: 2px solid #000000 !important;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
	background-color: #000000 !important;
}

.react-google-flight-datepicker .day.selected.hovered::after,
.react-google-flight-datepicker .day.selected::after {
	border-radius: 100%;
	background-color: #000000 !important;
}

.react-google-flight-datepicker .calendar-wrapper {
	min-height: 250px;
}

.react-google-flight-datepicker .day::after {
	height: 3rem;
	width: 3rem;
}

.react-google-flight-datepicker .day.hovered {
	background-color: #d8d8d8c2;
}

.react-google-flight-datepicker .icon-calendar {
	fill: #000000;
}

.react-google-flight-datepicker .day.selected.hovered:hover {
	color: #ffffff;
}

.react-google-flight-datepicker .flipper-button {
	background-color: #fff;
	border-radius: 100%;
	box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
	cursor: pointer;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 -15px;
	pointer-events: all;
}

.react-google-flight-datepicker .month-calendar {
	width: 220px;
	color: #3c4043;
	margin-right: 43px;
}



.tabset {
	width: 100%;
	margin-top: 1.5rem;
	font-family: "Poppins", sans-serif;

}

.tabset>input[type="radio"] {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.tabset .tab-panel {
	display: none;
	background: white;
	min-width: 100rem;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
	display: block;
	padding: 1.5rem;
	border-radius: 0.5rem;
}

.tabset>label {
	position: relative;
	display: inline-block;
	margin-right: .5rem;
	padding: 1rem;
	border: 1px solid var(--grey-border);
	border-bottom: 0;
	cursor: pointer;
	font-size: 1.5rem;
	justify-content: center;
	text-align: center;
	align-items: center;
	padding: 1.5rem;
	font-family: "Poppins", sans-serif;
}

.tabset>label:hover,
.tabset>input:focus+label {
	color: var(--primary-color);
}

.tabset>input:checked+label {
	border-color: #ccc;
	border-bottom: 1px solid #fff;
	margin-bottom: -1px;
	color: var(--primary-color);
	background: white;
	border-radius: 1rem 1rem 0rem 0rem;
}

.tab-panel {
	border-top: 1px solid var(--grey-border);
	padding-top: 1rem;
	min-width: 80rem;
}