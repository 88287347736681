.paragraphText {
	font-size: 1.5rem;
	margin-top: 1.5rem;
}

.agreementImage {
	max-width: 500rem;
}

.signDataAddBtn {
	margin-top: .5rem;
	background-color: transparent;
	border: none;
	font-family: inherit;
	font-size: 1.1rem;
	color: #ff9000;
	font-weight: 500;
	background-color: transparent;
	border: 1px solid #ff9000;
	padding: .2rem 1rem;
	border-radius: 1rem;
	cursor: pointer;
	height: 3rem;
	display: flex;
	align-items: center;
	transition: all .4s;
}

.signDataAddBtnIcon {
	height: 1rem;
	width: 1rem;
	fill: #e7e7ea;
}

.signDataAddBtn:hover {
	background-color: #ff9000;
	border: 1px solid #ff9000;
	color: #000000;
}
