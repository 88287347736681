.WorkOutCard_Container {
	gap: 0.5rem;
	height: 100%;
	width: 33.3%;
	background-color: #ffffff;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	border-radius: 2rem;
	overflow: hidden;
	padding: 2rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
}

.title {
	font-size: 2.2rem;
	color: #ff9000;
	margin-bottom: 1rem;
}


.roundTitle {
	width: 100%;
	display: inline-block;
	font-size: 1.8rem;
	font-weight: 500;
	color: #000000;
}

.roundNumbers {
	font-size: 1.8rem;
	width: 5rem;
	border-radius: 5px;
	background: rgba(161, 98, 247, 0.1);
	color: #000000;
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;
	padding: 0.5rem 1rem;
	margin-top: 0.2rem;
	margin-bottom: 0.2rem;
	min-height: 4rem;
}

.roundName {
	width: 100%;
	display: inline-block;
	font-size: 1.6rem;
	font-weight: 400;
	color: #000000;
}

.innerContent {
	width: 100%;
	display: inline-block;
}

.roundFlex {
	grid-template-columns: 5rem repeat(6, 1fr) 5rem;
	display: grid;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	align-items: center;
}

.innerContent>div:nth-of-type(1) {
	border-top: 1px solid #F3F3F3;
}
 
.button_icon {
	width: '1.4rem';
	height: '1.4rem';
}

.roundInfo {
	font-size: 1.2rem;
}


@media (max-width: 1880px) {

	.title {
		font-size: 1.8rem;
		color: #ff9000;
	}

	.roundTitle {
		width: 100%;
		display: inline-block;
		font-size: 1.6rem;
		font-weight: 500;
		color: #000000;
	}

	.roundNumbers {
		font-size: 1.6rem;
		width: 5rem;
		border-radius: 5px;
		background: rgba(161, 98, 247, 0.1);
		color: #000000;
		display: flex;
		align-items: center;
		text-align: center;
		flex-direction: column;
		padding: 0.5rem 1rem;
		margin-top: 0.2rem;
		margin-bottom: 0.2rem;
		min-height: 3rem;
	}

	.roundName {
		width: 100%;
		display: inline-block;
		font-size: 1.4rem;
		font-weight: 400;
		color: #000000;
	}
}

@media (max-width: 1100px) {
	.title {
		font-size: 1rem;
		color: #ff9000;
		margin: 0;
	}

	.roundName {
		width: 100%;
		display: inline-block;
		font-size: 0.8rem;
		font-weight: 400;
		color: #000000;
	}

	.roundTitle {
		width: 100%;
		display: inline-block;
		font-size: 0.8rem;
		font-weight: 500;
		color: #000000;
	}

	.button_icon {
		width: 0.8rem  !important;
		height: 0.8rem  !important;
	}

	.roundNumbers {
		font-size: 0.8rem;
		padding: 0.2rem 0.3rem 0.3rem 0.3rem;
		height: 1.2rem;
		min-height: 1.8rem;
		width: 2.4rem;
		border-radius: 4px;
	}

	.reportHeader {
		width: auto !important;
		height: auto !important;
	}

	.WorkOutCard_Container {
		padding: 1rem;
		height: auto;
	}

	.roundInfo {
		font-size: 0.7rem;
	}
}


@media (max-width: 768px) {
	.title {
		font-size: 0.8rem;
		color: #ff9000;
		margin: 0;
	}

	.roundName {
		width: 100%;
		display: inline-block;
		font-size: 0.7rem;
		font-weight: 400;
		color: #000000;
	}

	.roundTitle {
		width: 100%;
		display: inline-block;
		font-size: 0.7rem;
		font-weight: 500;
		color: #000000;
	}

	.button_icon {
		width: 0.7rem  !important;
		height: 0.7rem  !important;
	}

	.roundNumbers {
		font-size: 0.7rem;
		padding: 0.1rem 0.2rem 0.1rem;
		height: 1.2rem;
		min-height: 1.2rem;
		width: 1.6rem;
		border-radius: 3px;
	}

	.reportHeader {
		width: auto !important;
		height: auto !important;
	}

	.WorkOutCard_Container {
		padding: 1rem;
		height: auto;
	}

	.roundInfo {
		font-size: 0.6rem;
	}
}


@media (max-width: 640px) {
	.title {
		font-size: 0.6rem;
		color: #ff9000;
		margin: 0;
	}

	.roundName {
		width: 100%;
		display: inline-block;
		font-size: 0.6rem;
		font-weight: 400;
		color: #000000;
	}

	.roundTitle {
		width: 100%;
		display: inline-block;
		font-size: 0.6rem;
		font-weight: 500;
		color: #000000;
	}

	.button_icon {
		width: 0.7rem  !important;
		height: 0.7rem  !important;
	}

	.roundNumbers {
		font-size: 0.6rem;
		padding: auto;
		height: 1rem;
		min-height: 1rem;
		width: 1.5rem; 
		border-radius: 3px;
	}

	.reportHeader {
		width: auto !important;
		height: auto !important;
	}

	.WorkOutCard_Container {
		padding: 1rem;
		height: auto;
		gap: 0.3rem;
	}

	.roundInfo {
		font-size: 0.5rem;
	}
}