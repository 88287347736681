.templates {
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.template {
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	text-decoration: none;
	color: #000000;
	width: 22rem;
	transition: all .4s;
}
.template:hover {
	transform: scale(1.03);
}
.template1 {
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	text-decoration: none;
	color: #000000;
	transition: all .4s;
}
.template1:hover {
	transform: scale(1.08);
}
.templateData {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: center;
	justify-content: center;
	height: 28rem;
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem 3rem;
}
.templateImage {
	padding: 2rem;
	margin-top: 4rem;
	border-radius: 50%;
	background-color: #ff9000;
	display: flex;
	align-items: center;
}
.templateImageIcon {
	height: 4rem;
	width: 4rem;
	fill: #e7e7ea;
}
.templateName {
	font-size: 1.8rem;
	font-weight: 600;
	text-align: center;
}
.templateButtonIcon {
	height: 3rem;
	width: 3rem;
}
.templateBar {
	height: .5rem;
	margin: 1.5rem 1rem;
	width: 26rem;
	background-color: #ff9000;
}

.viewButton{
	font-size: 1.4rem;
	font-weight: 600;
	border-bottom: 1px solid #c6c6cd;
	cursor: pointer;
	user-select: none;
}

.templateCardActions {
	border-top: 1px solid #e7e7ea;
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: auto;
}

.templateCardActionBtn {
	display: flex;
	align-items: center;
	gap: .5rem;
}

.templateCardAction {
	background-color: transparent;
	border: 1px solid #12151a;
	padding: .6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}
.templateCardActionIcon {
	height: 1.2rem;
	width: 1.2rem;
	fill: #12151a;
	transition: all .4s;
}
.templateCardAction:hover {
	background-color: #12151a;
	border: 1px solid #12151a;
}
.templateCardAction:hover .templateCardActionIcon {
	fill: #ffffff;
}
