.reportHeader--tab {
	display: flex;
	gap: 1rem;
}

.report_chart_container--section {
	display: flex;
	gap: 2rem;
	background-color: #ffffff;
	padding: 1.5rem;
	padding-bottom: 0.8rem;
	border-radius: 2rem;
	align-items: center;
	margin-bottom: 0.5rem;
}

.month_filter {
	display: flex;
	gap: 2rem;
	align-items: center;
	width: max-content;
	background-color: #ffffff;
	border-radius: 1rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	margin: 1rem 0;
}

.month_filter .textBox__input--box {
	margin-left: 0rem;
}

@media (max-width: 1024px) {
	.reportHeader--tab {
		position: relative;
		width: 100%;
	}
}
