.textBox {
	position: relative;
}

.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
}

.textBox__label {
	position: absolute;
	top: -.5rem;
	left: 0;
	z-index: 10;
	display: flex;
	padding: 1.3rem;
}

.textBox__label--icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: #12151a;
}

.textBox__input {
	background-color: #e7e7ea;
	border-radius: 0.5rem;
	overflow: hidden;
	width: 25rem;
	display: flex;
	padding-right: 1rem;
}

.textBox__input--box {
	width: 100%;
	margin-left: 5rem;
	background-color: #e7e7ea;
	font-family: inherit;
	border: none;
	outline: none;
	padding: 1rem 1rem;
	color: #12151a;
}
.textBox__input--box option {
	background-color: #e7e7ea;
	border: none;
	border-color: #12151a;
	outline: none;
}

.textBox__input--show {
	margin-right: 1rem;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.textBox__input--show:hover .textBox__input--showIcon {
	fill: #ff3366;
}

.textBox__input--showIcon {
	height: 2rem;
	width: 2rem;
	fill: #12151a;
	transition: all .4s;
}

.form_input {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: flex-start;
}

.form_input__label {
	color: #8b8b8b;
	font-size: 1.2rem;
}
