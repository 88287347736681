.screenCards {
	margin-top: 2rem;
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
}
.screenCard {
	background-color: #ececec;
	border-radius: 2rem;
	padding: 2rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.screenCardTitle {
	margin-bottom: 2rem;
	font-size: 1.5rem;
	font-weight: 600;
}
