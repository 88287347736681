.muscleType {
	display: flex;
	gap: 1rem;
	align-items: center;
}
.muscleTypeSelectBox {
	background-color: #ffffff;
	border-radius: .5rem;
	border: none;
	outline: none;
	padding: .5rem 1rem;
	font-family: inherit;
	font-size: 1.5rem;
}

.muscleTypeGoBtn {
	background-color: #ff9000;
	padding: .5rem;
}

.muscleTypeGoBtnIcon {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}

.muscleTypeGoBtn,
.muscleTypeEditBtn {
	font-family: inherit;
	border: none;
	border-radius: .5rem;
	cursor: pointer;
	color: #e7e7ea;
	display: flex;
	align-items: center;
	gap: .5rem;
}
.muscleTypeEditBtn {
	background-color: #09baa6;
	padding: .5rem 1.5rem;
}
.muscleTypeEditBtnIcon {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
}

/* Round Card */
.roundCards {
	display: flex;
	gap: 1.5rem;
	align-items: stretch;
	flex-wrap: wrap;
	margin-top: 3rem;
}
.roundCard {
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;
	width: 40rem;
	height: fit-content;
}
.roundCardHeading {
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--bg-primary);
}
.roundCardExercise {
	display: flex;
	gap: 3rem;
	font-size: 1.2rem;
	font-weight: 600;
	align-items: center;

}
.roundCardExerciseReps{
	color: #ff9000;
	font-size: 5rem;
}
.roundCardDetail1{
	width: 70%;
}
.roundCardText{
	font-size: 1.5rem;
    font-weight: 400;
    margin-right: 10px;
   min-width: 120px
}
.roundCardInfo{
	font-size: 1.5rem;
    font-weight: 500;
    /* width: 111px; */
    height: auto;
}
.roundCardDetail2{
	text-align: center;
}
.roundCardExerciseName {
	font-size:2rem;
	color :var(--bg-primary)
}
.roundCardRepType {
	font-size: 1.3rem;
	font-weight: 600;
}
.roundCardRepType2 {
	font-size: 1.1rem;
	font-weight: 600;
}
.roundCardNote {
	font-size: 1.1rem;
	font-weight: 600;
}
.roundCardNextRound {
	color: #09baa6;
	font-size: 1.2rem;
	font-weight: 600;
}

/* Add Muscle Type */
.inputBoxContainers {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1.5rem;
	margin-bottom: 2rem;
}
.inputBoxContainer {
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;
}
.inputBoxes {
	display: flex;
	gap: 1rem;
}
