.videoCards {
	display: flex;
	align-items: stretch;
	gap: 2rem;
	flex-wrap: wrap;
	padding: 1rem 0;
}
.videoCard {
	text-decoration: none;
	color: currentColor;
	background-color: #ffffff;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
	border-radius: 2rem;
	overflow: hidden;
	padding: 2rem;
	cursor: pointer;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
}
.videoCard:hover {
	transform: translateY(-.2rem);
}
.videoCard__iImageIcon {
	position: relative;
	width: 100%;
}
.videoCard__iImageIcon_image {
	height: 10rem;
	border-radius: 2rem;
	width: 100%;
}
.videoCard__iImageIcon_icon_container {
	background-color: #ff9000;
	display: flex;
	padding: .5rem;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-40%, -40%);
}
.videoCard__iImageIcon_icon {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
	transition: all .4s;
}
.videoCard__iImageIcon_icon:hover {
	height: 2.5rem;
	width: 2.5rem;
}
.videoCard__title {
	text-decoration: none;
	color: #12151a;
	font-size: 1.5rem;
	font-weight: 600;
	margin-top: 1rem;
	width: 17rem;
}
.videoCard__subTitle {
	font-size: 1.1rem;
	font-weight: 400;
	color: #12151a;
	width: 17rem;
}
.videoCardCategory {
	border: 1px solid #09baa6;
	padding: .2rem 1rem;
	border-radius: 2rem;
	color: #09baa6;
	font-size: 1.2rem;
}
.videoCardStats {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
}
.videoCardStatsCount {
	display: flex;
	gap: 1rem;
	align-items: center;
}
.videoCardStat {
	display: flex;
	align-items: center;
	gap: .5rem;
	color: #09baa6;
}
.videoCardStatIcon {
	height: 1.5rem;
	width: 1.5rem;
	fill: #09baa6;
}
.videoCardActions {
	border-top: 1px solid #e7e7ea;
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;

	display: flex;
	align-items: center;
	justify-content: space-between;
}
.videoCardActionBtn {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.videoCardAction {
	background-color: #12151a;
	border: 1px solid #12151a;
	padding: .5rem .7rem;
	padding-bottom: .8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}
.videoCardActionIcon {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
	transition: all .4s;
}
.videoCardAction:hover {
	background-color: transparent;
	border: 1px solid #12151a;
}
.videoCardAction:hover .videoCardActionIcon {
	fill: #12151a;
}
