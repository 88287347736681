.textBox {
	position: relative;
}

.form_input__error {
	font-size: 1.2rem;
	color: var(--red);
}

.textBox__label {
	position: absolute;
	top: -.5rem;
	left: 0;
	z-index: 10;
	display: flex;
	padding: 1.3rem;
}

.textBox__label--icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--dark-grey);
}

.textBox_select_input {
	background-color: var(--white);
	border-radius: 0.5rem;
	overflow: hidden;
	width: 25rem;
	display: flex;
	padding-right: 0rem;
}

.textBox_select_input--box {
	width: 100%;
	margin-left: 5rem;
	background-color: var(--white);
	font-family: inherit;
	border: none;
	outline: none;
	padding: 0.2rem 0rem;
	color: var(--dark-grey);
}    
.form_input {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: flex-start;
}

.form_input__label {
	color: var(--light-grey);
	font-size: 1.2rem;
}
