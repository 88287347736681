.signDataAddBtn {
	font-family: inherit;
	font-size: 1.2rem;
	color: #000000;
	font-weight: 500;
	background-color: transparent;
	border: 1px solid #000000;
	padding: .5rem 2rem;
	border-radius: 2rem;
	cursor: pointer;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: center;
	transition: all .4s;
}
.signDataAddBtnIcon {
	height: 1.5rem;
	width: 1.5rem;
	fill: #000000;
	transition: all .4s;
}
.signDataAddBtn:hover {
	background-color: #000000;
	border: 1px solid #000000;
	color: #e7e7ea;
}
.signDataAddBtn:hover .signDataAddBtnIcon {
	fill: #e7e7ea;
}
.selectBox {
	font-family: inherit;
	border: none;
	outline: none;
	background-color: #007c6e;
	color: #e7e7ea;
	padding: .5rem 1rem;
	border-radius: .5rem;
}
