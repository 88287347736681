.skeleton {
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
}
.skeleton::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(240, 240, 241, 0.1), rgba(211, 219, 219, 0.7), rgba(231, 231, 234, 0.1));
	animation: progress 1.5s ease-in-out infinite;
}
@keyframes progress {
	0% {
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		transform: translate3d(100%, 0, 0);
	}
}
.SidebarSkeleton {
	margin-left: 2rem;
}
.navigation__logo_skeleton {
	height: 6rem;
	width: 15rem;
	background-color: #ececec;
	margin-bottom: 4rem;
}
.navigation__list {
	height: 3rem;
	width: 18rem;
	background-color: #ececec;
	margin-top: 1.2rem;
}
.chartSkeleton {
	margin: 2rem 0;
	height: 30.5rem;
	width: 100%;
	background-color: #ffffff;
	border-radius: 2rem;
}
.cardSkeletons {
	display: flex;
	gap: 1rem;
	align-items: center;
}
.cardSkeleton {
	height: 13rem;
	width: 17vw;
	background-color: #ffffff;
}
.labelSkeleton {
	height: 1rem;
	width: 10rem;
	background-color: #e7e7ea;
}
.textBoxSkeleton {
	margin-top: .5rem;
	margin-bottom: 1rem;
	height: 4rem;
	width: 20rem;
	background-color: #e7e7ea;
}
.textBoxSkeleton2 {
	margin-top: .5rem;
	margin-bottom: 1rem;
	height: 4rem;
	width: 25rem;
	background-color: #e7e7ea;
}
.buttonSkeleton {
	height: 4rem;
	width: 10rem;
	background-color: #e7e7ea;
	margin-bottom: 2rem;
}
.buttonSkeleton2 {
	height: 4rem;
	width: 10rem;
	background-color: #ffffff;
	margin-bottom: 2rem;
}
.buttonSkeleton3 {
	height: 4.5rem;
	width: 20rem;
	background-color: #ffffff;
	margin-bottom: 2rem;
}
.textSkeleton {
	height: 1rem;
	width: 15rem;
	background-color: #e7e7ea;
}

/* App Settings Skeleton */
.logoUploadContainer {
	background-color: #ffffff;
	border-radius: 2rem;
	overflow: hidden;
}
.logoUploadContainerImage {
	height: 16rem;
	width: 33.5rem;
	padding: 1rem 2rem;
}
.uploadImageSkeleton {
	height: 19rem;
	width: 19rem;
	background-color: #ffffff;
}
.uploadImageSkeleton2 {
	height: 19rem;
	width: 31rem;
	background-color: #ffffff;
}
