.productDetails {
	margin-top: 1rem;
	display: flex;
	gap: 2rem;
	justify-content: space-around;
}
.productDetails__subTitle {
	margin: 1rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: #8b8b8b;
}
.productDetailsSection product {
	/* height: 55vh; */
	width: 52vw;
	border-radius: 2rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a;
}

.product_detail_screen_main--content {
	display: flex;
}

.product_detail_screen_main-left {
	border-radius: 25px;
	transition: 0.3s;
	padding: 3rem 4rem;
	width: 60%;
}

.product_detail_screen_main_right {
	border-radius: 5rem;
	transition: 0.3s;
	padding-top: 2rem;
	display: flex;
}

.product_detail_screen_main_image {
	width: 100%;
	max-width: 30rem;
	border-radius: 2rem;
}
